import { Icon } from "@chakra-ui/react";
import { Color } from "../../themes";

interface SwitchIconProps<T> {
  options?: T;
}

export function SwitchIcon<T>(props: SwitchIconProps<T>) {
  return (
    <Icon width="1.5rem" height="1.5rem" viewBox="0 0 20 28" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d={`M16.0954 21.6343V12.4762C16.0954 11.6381 15.4097 10.9524 14.5716 10.9524C13.7335 
        10.9524 13.0478 11.6381 13.0478 12.4762V21.6343H10.3202C9.63447 21.6343 9.29923 22.4572 
        9.78685 22.9296L14.0383 27.1657C14.343 27.4553 14.8154 27.4553 15.1202 27.1657L19.3716 
        22.9296C19.8592 22.4572 19.5088 21.6343 18.8383 21.6343H16.0954ZM4.89542 0.819072L0.643996 
        5.0705C0.156377 5.54288 0.491614 6.36574 1.17733 6.36574H3.90495V15.5238C3.90495 16.3619 
        4.59066 17.0476 5.42876 17.0476C6.26685 17.0476 6.95257 16.3619 6.95257 15.5238V6.36574H9.68019C10.3659 
        6.36574 10.7011 5.54288 10.2135 5.0705L5.96209 0.819072C5.67257 0.529548 5.18495 0.529548 4.89542 0.819072Z`}
        fill={Color.Black_01}
      />
    </Icon>
  );
}
