export const Paths = {
  Home: "/",
  Swap: {
    default: "swap",
  },
  Pools: {
    default: "pools",
    AddLiquidity: "pools/add-liquidity",
    Withdraw: "pools/withdraw",
    CreatePool: "pools/create-pool",
  },
  Governance: {
    absolute: "/governance",
    default: "governance",
    ProposalDetails: "proposal-details",
    CreateNewProposal: "create-new-proposal",
    CreateNewToken: "new-token",
    CreateText: "text",
    CreateTokenTransfer: "token-transfer",
    CreateContractUpgrade: "contract-upgrade",
  },
  DAOs: {
    absolute: "/daos",
    default: "daos",
    Create: "create",
    Overview: "overview",
    DAODetails: "details",
    type: "type",
    GovernanceToken: "governance-token",
    GovernanceTokenVoting: "governance-token/voting",
    GovernanceTokenReview: "governance-token/review",
    Multisig: "multisig",
    MultisigVoting: "multisig/voting",
    MultisigReview: "multisig/review",
    NFT: "nft",
    NFTVoting: "nft/voting",
    NFTReview: "nft/review",
    Proposals: "proposals",
    Assets: "assets",
    Members: "members",
    Settings: "settings",
    Staking: "staking",
    AddMember: "add-member",
    ChangeDAOSettings: "change-dao-details",
    DeleteMember: "delete-member",
    ReplaceMember: "replace-member",
    ChangeThreshold: "change-threshold",
    DetailsStep: "details",
    ReviewStep: "review",
    CreateDAOProposal: "new-proposal",
    DAOProposalType: "type",
    DAOTextProposalDetails: "text/details",
    DAOTextProposalReview: "text/review",
    DAOTokenTransferDetails: "token-transfer/details",
    DAOTokenTransferReview: "token-transfer/review",
    DAOAddMemberDetails: "add-member/details",
    DAOAddMemberReview: "add-member/review",
    DAODeleteMemberDetails: "remove-member/details",
    DAODeleteMemberReview: "remove-member/review",
    DAOReplaceMemberDetails: "replace-member/details",
    DAOReplaceMemberReview: "replace-member/review",
    DAOUpgradeThresholdDetails: "upgrade-threshold/details",
    DAOUpgradeThresholdReview: "upgrade-threshold/review",
    DAOContractUpgradeDetails: "contract-upgrade/details",
    DAOContractUpgradeReview: "contract-upgrade/review",
    DAOTokenAssociateDetails: "token-associate/details",
    DAOTokenAssociateReview: "token-associate/review",
  },
};
