import { Link, Text, Flex } from "@chakra-ui/react";
import { Color, GithubIcon } from "@dex-ui-components";

export function PageFooter() {
  return (
    <Flex layerStyle="footer">
      <Text textStyle="p xsmall regular">&nbsp;</Text>
      <Flex direction="row" alignItems="center" gap="8">
       <Text textStyle="p xsmall regular">WEB23 © 2023, Powered by: Hedera Hashgraph</Text>
        
      </Flex>
    </Flex>
  );
}
