import { createIcon } from "@chakra-ui/react";

export const LockIcon2 = createIcon({
  displayName: "LockIcon2",
  viewBox: "0 0 17 20",
  path: (
    <path
      d="M4.33333 9.16663V5.83329C4.33333 4.72822 4.77232 3.66842 5.55372 2.88701C6.33512 2.10561 7.39493 
            1.66663 8.5 1.66663C9.60507 1.66663 10.6649 2.10561 11.4463 2.88701C12.2277 3.66842 12.6667 4.72822 
            12.6667 5.83329V9.16663M2.66667 9.16663H14.3333C15.2538 9.16663 16 9.91282 16 10.8333V16.6666C16 
            17.5871 15.2538 18.3333 14.3333 18.3333H2.66667C1.74619 18.3333 1 17.5871 1 16.6666V10.8333C1 9.91282 
            1.74619 9.16663 2.66667 9.16663Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  ),
});
